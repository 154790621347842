export const weekdays = [
  {
    id: "1",
    name: "Sunday",
  },
  {
    id: "2",
    name: "Monday",
  },
  {
    id: "3",
    name: "Tuesday",
  },
  {
    id: "4",
    name: "Wednesday",
  },
  {
    id: "5",
    name: "Thursday",
  },
  {
    id: "6",
    name: "Friday",
  },
  {
    id: "7",
    name: "Saturday",
  },
];
